import { FormEvent, useEffect, useState } from "react";
import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Aluno } from "../components/Aluno";
import { useProfessores } from "../hooks/useProfessores";
import { useAuth } from "../hooks/useAuth";
import { AlunoType, ModuloType, PesquisaType } from "../params";
import { database } from "../services/firebase";
import swal from "sweetalert";
import XLSX, { Table2SheetOpts } from "xlsx";

export function Modulo() {
    const { user } = useAuth();
    const params = useParams<{ id: string }>();
    const moduloId = params.id;

    const [nome, setNome] = useState("");
    const [turma, setTurma] = useState("");
    const [professor, setProfessor] = useState("");
    const [auditorio, setAuditorio] = useState("");
    const [capacidade, setCapacidade] = useState("");
    const [dataAberturaPesquisa, setDataAberturaPesquisa] = useState("");
    const [dataFechamentoPesquisa, setDataFechamentoPesquisa] = useState("");

    const [dataAula1, setDataAula1] = useState("");
    const [dataAula2, setDataAula2] = useState("");
    const [dataAula3, setDataAula3] = useState("");
    const [dataAula4, setDataAula4] = useState("");

    const professores = useProfessores();

    const [pesquisas, setPesquisas] = useState<PesquisaType[]>([]);

    const [alunos, setAlunos] = useState<AlunoType[]>([]);

    useEffect(() => {
        if (user === undefined || user.tipo !== "admin") {
            return;
        }

        const ref = database.ref(`modulos/${moduloId}`);
        ref.once("value", (snapshot) => {
            const data: ModuloType = snapshot.val();
            if (null !== data) {
                setNome(data.nome);
                setTurma(data.turma);
                setProfessor(data.professor);
                setAuditorio(data.auditorio);
                setCapacidade(data.capacidade);
                setDataAberturaPesquisa(data.dataAberturaPesquisa);
                setDataFechamentoPesquisa(data.dataFechamentoPesquisa);
                setDataAula1(data.dataAula1);
                setDataAula2(data.dataAula2);
                setDataAula3(data.dataAula3);
                setDataAula4(data.dataAula4);
                setPesquisas(data.pesquisas);
            }
        });

        return () => {
            ref.off();
        };
    }, [moduloId, user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    async function handleIncluirAluno() {
        const value = await swal("", {
            content: { element: document.getElementById("popup") || "" },
        });
        alunos.push({ nome: value, cpf: "", avatar: "" });
        console.log(alunos);
    }

    async function handleRemoverAluno(nome: string) {
        const willDelete = await swal({
            title: "Você tem certeza?",
            text: `Você deseja retirar o aluno ${nome} deste módulo?`,
            icon: "warning",
            dangerMode: true,
        });

        if (user && willDelete) {
            await database
                .ref(`modulos/${moduloId}/pesquisas/`)
                .orderByChild("nome")
                .equalTo(nome)
                .on("child_added", function (snapshot) {
                    snapshot.ref.update({ removidoPor: user.id });
                });
            swal("Removido!", "O aluno foi removido deste módulo", "success");
        }
    }

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (nome.trim() === "") {
            return;
        }

        await database.ref(`modulos/${moduloId}`).update({
            nome: nome,
            turma: turma,
            professor: professor,
            auditorio: auditorio,
            capacidade: capacidade,
            dataAberturaPesquisa: dataAberturaPesquisa,
            dataFechamentoPesquisa: dataFechamentoPesquisa,
            dataAula1: dataAula1,
            dataAula2: dataAula2,
            dataAula3: dataAula3,
            dataAula4: dataAula4,
        });

        swal("Sucesso!", "O módulo foi alterado com sucesso!", "success");
    }

    function exportar(type: string, fn: string, dl: boolean) {
        var elt = document.getElementById("listaCompleta");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Pesquisa" } as Table2SheetOpts);
        return dl
            ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" } as XLSX.WritingOptions)
            : XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
    }

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Alterar módulo</div>
            </div>
            <div style={{ display: "none" }}>
                <div id="popup">
                    <div>
                        <p>teste1</p>
                        <p>teste2</p>
                    </div>
                </div>
            </div>
            {(user === undefined || user.tipo !== "admin") && (
                <div className="row">
                    <div className="col-12">
                        <div className="denied alert alert-danger fade show" role="alert">
                            Acesso negado para {user?.tipo} {user?.email}
                        </div>
                    </div>
                </div>
            )}
            {user?.tipo === "admin" && (
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-default">
                            <div className="card-header">Informações</div>
                            <div className="card-body">
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup className="col-md-12">
                                        <FormLabel htmlFor="nome">Nome da disciplina ou módulo</FormLabel>
                                        <FormControl id="nome" onChange={(event) => setNome(event.target.value)} value={nome} required></FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-md-12">
                                        <FormLabel htmlFor="turma">Nome da turma original</FormLabel>
                                        <FormControl id="turma" onChange={(event) => setTurma(event.target.value)} value={turma} required></FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-md-12">
                                        <FormLabel htmlFor="professor">Professor</FormLabel>
                                        <select
                                            name="professor"
                                            className="custom-select custom-select-sm"
                                            onChange={(event) => setProfessor(event.target.value)}
                                            value={professor}
                                            required>
                                            <option value=""></option>
                                            {professores &&
                                                professores.map((obj, index) => {
                                                    if (obj.hidden === false)
                                                        return (
                                                            <option key={index} value={obj.nome}>
                                                                {obj.nome}
                                                            </option>
                                                        );
                                                    return "";
                                                })}
                                        </select>
                                    </FormGroup>
                                    <FormGroup className="col-md-9 float-left">
                                        <FormLabel htmlFor="auditorio">Auditório</FormLabel>
                                        <FormControl id="auditorio" onChange={(event) => setAuditorio(event.target.value)} value={auditorio} required />
                                    </FormGroup>
                                    <FormGroup className="col-md-3 float-left">
                                        <FormLabel htmlFor="capacidade">Capacidade disponível</FormLabel>
                                        <FormControl id="capacidade" onChange={(event) => setCapacidade(event.target.value)} value={capacidade} required />
                                    </FormGroup>
                                    <FormGroup className="col-md-6 float-left">
                                        <FormLabel htmlFor="dataAbertura">Data de abertura da pesquisa</FormLabel>
                                        <FormControl
                                            id="dataAbertura"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataAberturaPesquisa(event.target.value)}
                                            value={dataAberturaPesquisa}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-6 float-left">
                                        <FormLabel htmlFor="dataFechamento">Data de fechamento da pesquisa</FormLabel>
                                        <FormControl
                                            id="dataFechametno"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataFechamentoPesquisa(event.target.value)}
                                            value={dataFechamentoPesquisa}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup className="clearfix">
                                        <FormLabel className="col-md-12">Datas das aulas</FormLabel>
                                        <FormGroup className="col-md-3 float-left">
                                            <FormLabel>Aula 1</FormLabel>
                                            <FormControl
                                                id="dataAula1"
                                                data-inputmask="'mask': '99/99/9999'"
                                                onChange={(event) => setDataAula1(event.target.value)}
                                                value={dataAula1}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3 float-left">
                                            <FormLabel>Aula 2</FormLabel>
                                            <FormControl
                                                id="dataAula2"
                                                data-inputmask="'mask': '99/99/9999'"
                                                onChange={(event) => setDataAula2(event.target.value)}
                                                value={dataAula2}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3 float-left">
                                            <FormLabel>Aula 3</FormLabel>
                                            <FormControl
                                                id="dataAula3"
                                                data-inputmask="'mask': '99/99/9999'"
                                                onChange={(event) => setDataAula3(event.target.value)}
                                                value={dataAula3}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3 float-left">
                                            <FormLabel>Aula 4</FormLabel>
                                            <FormControl
                                                id="dataAula4"
                                                data-inputmask="'mask': '99/99/9999'"
                                                onChange={(event) => setDataAula4(event.target.value)}
                                                value={dataAula4}
                                                required
                                            />
                                        </FormGroup>
                                    </FormGroup>
                                    <div className="col-md-12">
                                        <Button type="submit">Cadastrar</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-default">
                            <div className="card-header">
                                Alunos
                                <a className="float-right" href="#" title="Incluir" onClick={handleIncluirAluno}>
                                    <em className="fa fa-plus"></em>
                                </a>
                                <a
                                    className="float-right"
                                    href="#"
                                    title="Exportar"
                                    onClick={(e) => {
                                        return exportar("xlsx", `Pesquisa ${nome} - ${turma}.xlsx`, false);
                                    }}
                                    style={{ marginRight: "12px" }}>
                                    <em className="far fa-file-excel"></em>
                                </a>
                            </div>
                            <div className="card-body">
                                {pesquisas &&
                                    Object.values(pesquisas ?? {})
                                        .sort((a, b) => (a.nome.toUpperCase() > b.nome.toUpperCase() ? 1 : -1))
                                        .map((aluno, index) => {
                                            if (!aluno.removidoPor)
                                                return (
                                                    <Aluno
                                                        key={index}
                                                        nome={aluno.nome}
                                                        cpf={aluno.cpf}
                                                        avatar={aluno.avatar}
                                                        presencial={aluno.presencial}
                                                        dataAula1={dataAula1}
                                                        dataAula2={dataAula2}
                                                        dataAula3={dataAula3}
                                                        dataAula4={dataAula4}
                                                        onRemover={() => {
                                                            handleRemoverAluno(aluno.nome);
                                                        }}
                                                    />
                                                );
                                            else return "";
                                        })}
                            </div>
                        </div>
                        <table id="listaCompleta" style={{ display: "none" }}>
                            <thead>
                                <tr>
                                    <th>Nome completo</th>
                                    <th>CPF</th>
                                    <th>Telefone</th>
                                    <th>Aula</th>
                                    <th>Respondeu em</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pesquisas &&
                                    Object.values(pesquisas ?? {})
                                        .sort((a, b) => (a.nome.toUpperCase() > b.nome.toUpperCase() ? 1 : -1))
                                        .map((aluno, index) => {
                                            if (!aluno.removidoPor)
                                                return (
                                                    <tr key={index}>
                                                        <td>{aluno.nome}</td>
                                                        <td>{aluno.cpf}</td>
                                                        <td>{aluno.telefone}</td>
                                                        <td>{aluno.presencial ? "Presencial" : "Zoom"}</td>
                                                        <td>{aluno.respondidoEm}</td>
                                                    </tr>
                                                );
                                            return <></>;
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}
