import { FormEvent, useEffect, useState } from "react";
import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useProfessores } from "../hooks/useProfessores";
import { database } from "../services/firebase";
import Inputmask from "inputmask";

export function CadastrarModulo() {
    const { user } = useAuth();
    const history = useHistory();

    const [nome, setNome] = useState("");
    const [turma, setTurma] = useState("");
    const [professor, setProfessor] = useState("");
    const [auditorio, setAuditorio] = useState("");
    const [capacidade, setCapacidade] = useState("");
    const [dataAberturaPesquisa, setDataAberturaPesquisa] = useState("");
    const [dataFechamentoPesquisa, setDataFechamentoPesquisa] = useState("");

    const [dataAula1, setDataAula1] = useState("");
    const [dataAula2, setDataAula2] = useState("");
    const [dataAula3, setDataAula3] = useState("");
    const [dataAula4, setDataAula4] = useState("");

    let professores = useProfessores();

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (nome.trim() === "") {
            return;
        }

        const newRef = await database.ref("modulos").push({
            nome: nome,
            turma: turma,
            professor: professor,
            auditorio: auditorio,
            capacidade: capacidade,
            dataAberturaPesquisa: dataAberturaPesquisa,
            dataFechamentoPesquisa: dataFechamentoPesquisa,
            authorId: user?.id,
            dataAula1: dataAula1,
            dataAula2: dataAula2,
            dataAula3: dataAula3,
            dataAula4: dataAula4,
        });

        history.push(`/modulo/${newRef.key}`);
    }

    useEffect(() => {
        Inputmask().mask(document.querySelectorAll("#dataAbertura, #dataFechamento, #dataAula1, #dataAula2, #dataAula3, #dataAula4"));
    });

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Cadastrar módulo</div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card card-default">
                        <div className="card-header">Informações</div>
                        <div className="card-body">
                            <Form onSubmit={handleSubmit}>
                                <FormGroup className="col-md-12">
                                    <FormLabel htmlFor="nome">Nome da disciplina ou módulo</FormLabel>
                                    <FormControl id="nome" onChange={(event) => setNome(event.target.value)} value={nome} required />
                                </FormGroup>
                                <FormGroup className="col-md-12">
                                    <FormLabel htmlFor="turma">Nome da turma original</FormLabel>
                                    <FormControl id="turma" onChange={(event) => setTurma(event.target.value)} value={turma} required />
                                </FormGroup>
                                <FormGroup className="col-md-12">
                                    <FormLabel htmlFor="professor">Professor</FormLabel>
                                    <select
                                        name="professor"
                                        className="custom-select custom-select-sm"
                                        onChange={(event) => setProfessor(event.target.value)}
                                        value={professor}
                                        required>
                                        <option value=""></option>
                                        {professores &&
                                            professores.map((professor, index) => {
                                                if (professor.hidden === false)
                                                    return (
                                                        <option key={index} value={professor.nome}>
                                                            {professor.nome}
                                                        </option>
                                                    );
                                                return "";
                                            })}
                                    </select>
                                </FormGroup>
                                <FormGroup className="col-md-9 float-left">
                                    <FormLabel htmlFor="auditorio">Auditório</FormLabel>
                                    <FormControl id="auditorio" onChange={(event) => setAuditorio(event.target.value)} value={auditorio} required />
                                </FormGroup>
                                <FormGroup className="col-md-3 float-left">
                                    <FormLabel htmlFor="capacidade">Capacidade disponível</FormLabel>
                                    <FormControl id="capacidade" onChange={(event) => setCapacidade(event.target.value)} value={capacidade} required />
                                </FormGroup>
                                <FormGroup className="col-md-6 float-left">
                                    <FormLabel htmlFor="dataAbertura">Data de abertura da pesquisa</FormLabel>
                                    <FormControl
                                        id="dataAbertura"
                                        data-inputmask="'mask': '99/99/9999'"
                                        onChange={(event) => setDataAberturaPesquisa(event.target.value)}
                                        value={dataAberturaPesquisa}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6 float-left">
                                    <FormLabel htmlFor="dataFechamento">Data de fechamento da pesquisa</FormLabel>
                                    <FormControl
                                        id="dataFechamento"
                                        data-inputmask="'mask': '99/99/9999'"
                                        onChange={(event) => setDataFechamentoPesquisa(event.target.value)}
                                        value={dataFechamentoPesquisa}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="clearfix">
                                    <FormLabel className="col-md-12">Datas das aulas</FormLabel>
                                    <FormGroup className="col-md-3 float-left">
                                        <FormLabel>Aula 1</FormLabel>
                                        <FormControl
                                            id="dataAula1"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataAula1(event.target.value)}
                                            value={dataAula1}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-3 float-left">
                                        <FormLabel>Aula 2</FormLabel>
                                        <FormControl
                                            id="dataAula2"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataAula2(event.target.value)}
                                            value={dataAula2}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-3 float-left">
                                        <FormLabel>Aula 3</FormLabel>
                                        <FormControl
                                            id="dataAula3"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataAula3(event.target.value)}
                                            value={dataAula3}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-3 float-left">
                                        <FormLabel>Aula 4</FormLabel>
                                        <FormControl
                                            id="dataAula4"
                                            data-inputmask="'mask': '99/99/9999'"
                                            onChange={(event) => setDataAula4(event.target.value)}
                                            value={dataAula4}
                                            required
                                        />
                                    </FormGroup>
                                </FormGroup>
                                <div className="col-md-12">
                                    <Button type="submit">Cadastrar</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="col-md-6"></div>
            </div>
        </div>
    );
}
