import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContext";
import { Base } from "./components/Layout/Base";
import { BasePage } from "./components/Layout/BasePage";

// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "./styles/auth.scss";

// Font Awesome
import "@fortawesome/fontawesome-free/css/brands.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
// Animate.CSS
import "animate.css/animate.min.css";
// Simple line icons
import "simple-line-icons/css/simple-line-icons.css";

import { Modulos } from "./views/Modulos";
import { Modulo } from "./views/Modulo";
import { Professores } from "./views/Professores";
import { CadastrarModulo } from "./views/CadastrarModulo";
import { Checkin } from "./views/Checkin";
import { CheckinAlunoAula } from "./views/CheckinAlunoAula";
import { AcessoAutorizado } from "./views/AcessoAutorizado";
import { AcessoNegado } from "./views/AcessoNegado";
import { Pesquisas } from "./views/Pesquisas";
import { Pesquisa } from "./views/Pesquisa";
import { Login } from "./pages/Login";
import { Entrada } from "./pages/Entrada";
import { LoginCpf } from "./pages/LoginCpf";
import { CheckinProfessor } from "./views/CheckinProfessor";
import { DadosPessoais } from "./pages/DadosPessoais";
import { Terceiros } from "./views/Terceiros";
import { Funcionarios } from "./views/Funcionarios";
import { Hoje } from "./views/Hoje";
import { CheckinFuncionario } from "./views/CheckinFuncionario";
import { CheckinTerceiro } from "./views/CheckinTerceiro";
import { NotFound } from "./pages/NotFound";
import { Home } from "./pages/Home";
import { Logout } from "./pages/Logout";
import { LoginEmail } from "./pages/LoginEmail";
import { ListarQuestionarios } from "./views/ListarQuestionarios";
import { LoginAluno } from "./pages/LoginAluno";

function App() {
    return (
        <BrowserRouter>
            <AuthContextProvider>
                <Switch>
                    <Route path="/" exact={true}>
                        <BasePage>
                            <Home />
                        </BasePage>
                    </Route>
                    <Route path="/login">
                        <BasePage>
                            <Login />
                        </BasePage>
                    </Route>
                    <Route path="/login-aluno">
                        <BasePage>
                            <LoginAluno />
                        </BasePage>
                    </Route>
                    <Route path="/login-cpf">
                        <BasePage>
                            <LoginCpf />
                        </BasePage>
                    </Route>
                    <Route path="/login-email">
                        <BasePage>
                            <LoginEmail />
                        </BasePage>
                    </Route>
                    <Route path="/logout">
                        <BasePage>
                            <Logout />
                        </BasePage>
                    </Route>
                    <Route path="/dados-pessoais">
                        <BasePage>
                            <DadosPessoais />
                        </BasePage>
                    </Route>
                    <Route path="/home">
                        <Base>
                            <CheckinAlunoAula />
                        </Base>
                    </Route>
                    <Route path="/hoje">
                        <Base>
                            <Hoje />
                        </Base>
                    </Route>
                    <Route path="/cadastrar-modulo">
                        <Base>
                            <CadastrarModulo />
                        </Base>
                    </Route>
                    <Route path="/modulo/:id">
                        <Base>
                            <Modulo />
                        </Base>
                    </Route>
                    <Route path="/modulos">
                        <Base>
                            <Modulos />
                        </Base>
                    </Route>
                    <Route path="/pesquisas">
                        <Base>
                            <Pesquisas />
                        </Base>
                    </Route>
                    <Route path="/pesquisa/:id">
                        <Base>
                            <Pesquisa />
                        </Base>
                    </Route>
                    <Route path="/funcionarios">
                        <Base>
                            <Funcionarios />
                        </Base>
                    </Route>
                    <Route path="/professores">
                        <Base>
                            <Professores />
                        </Base>
                    </Route>
                    <Route path="/terceiros">
                        <Base>
                            <Terceiros />
                        </Base>
                    </Route>
                    <Route path="/acesso-autorizado/:dia/:id">
                        <Base>
                            <AcessoAutorizado />
                        </Base>
                    </Route>
                    <Route path="/acesso-negado/:dia/:id">
                        <Base>
                            <AcessoNegado />
                        </Base>
                    </Route>
                    <Route path="/checkin">
                        <Base>
                            <Checkin />
                        </Base>
                    </Route>
                    <Route path="/checkin-aluno">
                        <Base>
                            <Logout />
                        </Base>
                    </Route>
                    <Route path="/checkin-aluno-aula">
                        <Base>
                            <CheckinAlunoAula />
                        </Base>
                    </Route>
                    <Route path="/checkin-aula">
                        <Base>
                            <CheckinAlunoAula />
                        </Base>
                    </Route>
                    <Route path="/checkin-funcionario">
                        <Base>
                            <CheckinFuncionario />
                        </Base>
                    </Route>
                    <Route path="/checkin-professor">
                        <Base>
                            <CheckinProfessor />
                        </Base>
                    </Route>
                    <Route path="/checkin-terceiro">
                        <Base>
                            <CheckinTerceiro />
                        </Base>
                    </Route>
                    <Route path="/entrada/:dia/:id">
                        <Base>
                            <Entrada />
                        </Base>
                    </Route>
                    <Route path="/listar-questionarios/:userId">
                        <Base>
                            <ListarQuestionarios />
                        </Base>
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </AuthContextProvider>
        </BrowserRouter>
    );
}

export default App;
