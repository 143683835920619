import { ReactNode } from "react";
import { useHistory } from "react-router";

type Props = {
    children?: ReactNode;
    id: string;
    nome: string;
    turma: string;
    professor: string;
    datas: string;
    ocupacao: number;
    status: string;
};

export function ResumoPesquisa(props: Props) {
    const history = useHistory();

    return (
        <div className="col-xl-4 col-lg-6">
            <div className="card b">
                <div className="card-header">
                    <div className="float-right">{/*<div className={`badge badge-info ${props.status}`}>{props.status}</div>*/}</div>
                    <h4 className="m-0">{props.nome}</h4>
                    <small className="text-muted">{props.turma}</small>
                </div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Datas</strong>
                            </td>
                            <td>{props.datas}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Professor</strong>
                            </td>
                            <td>{props.professor}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="card-footer text-center">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(event) => {
                            history.push(`/pesquisa/${props.id}`);
                        }}>
                        Responder
                    </button>
                </div>
            </div>
        </div>
    );
}
