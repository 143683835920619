import { ReactNode } from "react";
import { useHistory } from "react-router";
import { PesquisaType } from "../params";
import Sparkline from "./Common/Sparklines";

type Props = {
    children?: ReactNode;
    id: string;
    nome: string;
    turma: string;
    professor: string;
    datas: string;
    ocupacao: number;
    status: string;
    pesquisas: Array<PesquisaType>;
};

export function ResumoModulo(props: Props) {
    const history = useHistory();

    return (
        <div className="col-xl-4 col-lg-6">
            <div className="card b">
                <div className="card-header">
                    <div className="float-right">
                        <div className={`badge badge-info ${props.status}`}>{props.status}</div>
                    </div>
                    <h4 className="m-0">{props.nome}</h4>
                    <small className="text-muted">{props.turma}</small>
                </div>
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="w-100" data-toggle="tooltip" data-title="Health">
                            <div className="progress progress-xs m-0">
                                <div className={`progress-bar ocupacao-${props.ocupacao}`} style={{ width: `${props.ocupacao}%` }}></div>
                            </div>
                        </div>
                        <div className="wd-xs text-right">
                            <div className="text-bold text-muted">{props.ocupacao} alunos</div>
                        </div>
                    </div>
                </div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Datas</strong>
                            </td>
                            <td>{props.datas}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Professor</strong>
                            </td>
                            <td>{props.professor}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Alunos</strong>
                            </td>
                            <td>
                                {props.pesquisas &&
                                    Object.values(props.pesquisas ?? {}).filter(value => !value.removidoPor).map((aluno, index) => {
                                        return (
                                            <a className="inline" href="#" key={index}>
                                                <img className="rounded-circle thumb24" src={aluno.avatar} alt={aluno.nome} />
                                            </a>
                                        );
                                    })}
                                
                                {/*<a className="inline" href="#">
                                    <strong>+5</strong>
                                </a>*/}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Link da pesquisa</strong>
                            </td>
                            <td>
                                {process.env.PUBLIC_URL}/pesquisa/{props.id}
                                <button
                                    className="btn btn-secondary ml-2"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`https://triagem.mmurad.com.br/pesquisa/${props.id}`);
                                    }}>
                                    <em className="far fa-copy"></em>
                                </button>
                                <button
                                    className="btn btn-secondary ml-2"
                                    onClick={() => {
                                        history.push(`/pesquisa/${props.id}`);
                                    }}>
                                    <em className="fas fa-link"></em>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Métricas</strong>
                            </td>
                            <td>
                                <Sparkline
                                    className="inline mr-2"
                                    options={{ type: "pie", height: "24", sliceColors: ["#edf1f2", "#23b7e5"] }}
                                    values="20,80"></Sparkline>
                                <Sparkline
                                    className="inline mr-2"
                                    options={{ type: "pie", height: "24", sliceColors: ["#edf1f2", "#27c24c"] }}
                                    values="60,40"></Sparkline>
                                <Sparkline
                                    className="inline"
                                    options={{ type: "pie", height: "24", sliceColors: ["#edf1f2", "#ff902b"] }}
                                    values="90,10"></Sparkline>
                                <div
                                    className="inline mr-2"
                                    data-sparkline=""
                                    data-values="20,80"
                                    data-type="pie"
                                    data-height="24"
                                    data-slice-colors='["#edf1f2", "#23b7e5"]'></div>
                                <div
                                    className="inline mr-2"
                                    data-sparkline=""
                                    data-values="60,40"
                                    data-type="pie"
                                    data-height="24"
                                    data-slice-colors='["#edf1f2", "#27c24c"]'></div>
                                <div
                                    className="inline"
                                    data-sparkline=""
                                    data-values="90,10"
                                    data-type="pie"
                                    data-height="24"
                                    data-slice-colors='["#edf1f2", "#ff902b"]'></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="card-footer text-center">
                    <button className="btn btn-secondary" type="button" onClick={() => history.push(`/modulo/${props.id}`)}>
                        Alterar
                    </button>
                </div>
            </div>
        </div>
    );
}
