import { ButtonHTMLAttributes } from "react";

type BooleanProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function Boolean(props: BooleanProps) {
    return (
        <div>
            <label className="c-radio" style={{"display": "none"}}>
                <input id={`${props.id}1`} type="radio" name={props.id} value="indeterminate" required />
                <span className="fa fa-circle fa-2x"></span> Indeterminado
            </label>
            <label className="c-radio">
                <input id={`${props.id}2`} type="radio" name={props.id} value="sim" required />
                <span className="fa fa-circle fa-2x"></span> Sim
            </label>
            <label className="c-radio">
                <input id={`${props.id}3`} type="radio" name={props.id} value="nao" required />
                <span className="fa fa-circle fa-2x"></span> Não
            </label>
        </div>
    );
}
