import { ResumoPesquisa } from "../components/ResumoPesquisa";
import { useAuth } from "../hooks/useAuth";
import { useModulos } from "../hooks/useModulos";

export function Pesquisas() {
    const { user } = useAuth();

    const modulos = useModulos(true);

    function datas(data1: string, data2: string, data3: string, data4: string): string {
        return (
            data1?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data2?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data3?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data4?.replace("/" + new Date().getFullYear(), "")
        );
    }

    return (
        <div className="content-wrapper">
            <div className="content-heading">Pesquisas de participação</div>
            {user === undefined && (
                <div className="row">
                    <div className="col-12">
                        <div className="denied alert alert-danger fade show" role="alert">
                            Acesso negado!
                        </div>
                    </div>
                </div>
            )}
            {user && (
                <div className="row">
                    {modulos &&
                        modulos.map((instance, index) => {
                            return (
                                <ResumoPesquisa
                                    key={index}
                                    id={instance.id}
                                    nome={instance.nome}
                                    turma={instance.turma}
                                    professor={instance.professor}
                                    datas={datas(instance.dataAula1, instance.dataAula2, instance.dataAula3, instance.dataAula4)}
                                    ocupacao={50}
                                    status={"Confirmado"}></ResumoPesquisa>
                            );
                        })}
                </div>
            )}
        </div>
    );
}
