import { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";
import { Funcionario } from "../params";
import { database } from "../services/firebase";
import Inputmask from "inputmask";
import swal from "sweetalert";

import "../styles/auth.scss";

export function Funcionarios() {
    const { user } = useAuth();

    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");

    const [funcionarios, setFuncionarios] = useState<Funcionario[]>([]);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (nome.trim() === "" || cpf.trim() === "" || !user || !user.id) {
            return;
        }

        const newRef = await database.ref("funcionarios").push({
            authorId: user.id,
            cpf: cpf,
            nome: nome,
        });

        if (newRef.key !== null) {
            setFuncionarios([...funcionarios, { authorId: user.id, cpf: cpf, id: newRef.key || "", nome: nome }].sort((a, b) => a.nome.localeCompare(b.nome)));

            setNome("");
            setCpf("");

            await swal("Sucesso!", "Informações cadastradas com sucesso!", "success");
        }
    };

    useEffect(() => {
        if (user === undefined || user.id === undefined || user.tipo !== "admin") {
            return;
        }

        const ref = database.ref("funcionarios");
        ref.orderByChild("nome").once("value", (snapshot) => {
            console.log("snapshot funcionarios");
            let parsedValues: Funcionario[] = [];

            snapshot.forEach((instance) => {
                let funcionario: Funcionario = instance.val();
                funcionario.id = instance.key || "";

                parsedValues.push(funcionario);
            });

            setFuncionarios(parsedValues);
        });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Inputmask().mask(document.querySelectorAll("#cpf"));
    });

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Funcionários</div>
            </div>

            {(user === undefined || user.tipo !== "admin") && (
                <div className="row">
                    <div className="col-12">
                        <div className="denied alert alert-danger fade show" role="alert">
                            Acesso negado para {user?.tipo} {user?.email}
                        </div>
                    </div>
                </div>
            )}
            {user && user.tipo === "admin" && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-default">
                                <div className="card-header">Informações</div>
                                <div className="card-body">
                                    <Form onSubmit={handleSubmit}>
                                        <div className="form-row align-items-center">
                                            <div className="col-md-6">
                                                <label className="sr-only" htmlFor="nome">
                                                    Nome completo
                                                </label>
                                                <input
                                                    className="form-control mb-2"
                                                    id="nome"
                                                    type="text"
                                                    placeholder="Nome completo"
                                                    onChange={(event) => setNome(event.target.value)}
                                                    value={nome}
                                                    required
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <label className="sr-only" htmlFor="cpf">
                                                    CPF
                                                </label>
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <em className="far fa-id-card"></em>
                                                        </div>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        data-inputmask="'mask': '999.999.999-99'"
                                                        id="cpf"
                                                        type="text"
                                                        placeholder="CPF"
                                                        onChange={(event) => setCpf(event.target.value)}
                                                        value={cpf}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-primary mb-2" type="submit">
                                                    Adicionar
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-default">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover" id="table-ext-1">
                                        <thead>
                                            <tr>
                                                <th>Nome completo</th>
                                                <th>CPF</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {funcionarios &&
                                                funcionarios.map((funcionario, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{funcionario.nome}</td>
                                                            <td>{funcionario.cpf}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
