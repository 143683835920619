import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { instituicao, publicURL, QuestionarioResumido } from "../params";
import { database } from "../services/firebase";
import QRCode from "qrcode";

export function AcessoAutorizado() {
    const { user } = useAuth();

    const params = useParams<{ dia: string; id: string }>();

    const [dia, setDia] = useState("");
    const [hora, setHora] = useState("");
    const [liberado, setLiberado] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [nome, setNome] = useState("");

    useEffect(() => {
        if (user === undefined) {
            setLoaded(false);
            return;
        }

        const ref = database.ref(`questionarios/${params.dia}/${params.id}`);
        ref.once("value", (snapshot) => {
            const data: QuestionarioResumido = snapshot.val();

            if (null !== data) {
                setDia(data.dia);
                setHora(data.hora);
                setLiberado(data.liberado);
                setLoaded(true);
                setNome(data.nome);

                QRCode.toCanvas(
                    document.getElementById("canvas"),
                    `${publicURL}/entrada/${params.dia}/${params.id}`,
                    { errorCorrectionLevel: "H" },
                    function (error) {
                        if (error) {
                            console.error(error);
                        }
                    }
                );
            }
        });

        return () => {
            ref.off();
        };
    }, [params, user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            {loaded && liberado && (
                <div className="col-md-12 text-center">
                    <p>
                        Obrigado por responder ao questionário do dia {dia} às {hora}, {nome}
                    </p>
                    <h1>TUDO CERTO, VOCÊ PODE COMPARECER À {instituicao}.</h1>

                    <p>
                        <strong>ATENÇÃO:</strong> ao chegar à {instituicao}, você deverá passar pela aferição de temperatura corporal. Em caso de estado febril,
                        temperatura igual ou acima de 37,5º C, o seu acesso não será autorizado. Neste caso, siga as orientações dos responsáveis pela triagem
                        na entrada/chegada.
                    </p>
                    <p>
                        Por favor, apresente o seu QR Code abaixo aos responsáveis na entrada da Conveniada quando solicitado; a cada entrada nas instalações da{" "}
                        {instituicao}, ainda que num mesmo dia, você sempre deverá apresentar o seu QR Code de liberação.
                    </p>
                </div>
            )}
            <div className="col-md-12 text-center">
                <canvas id="canvas"></canvas>
            </div>
        </div>
    );
}
