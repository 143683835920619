import { useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { QuestionarioResumido } from "../params";
import { database } from "../services/firebase";

export function Checkin() {
    const history = useHistory();
    const { user } = useAuth();

    useEffect(() => {
        if (user === undefined) {
            return;
        }

        const fullDate = new Date().toISOString().substr(0, 10);

        const ref = database.ref(`questionarios/${fullDate}`);
        ref.orderByChild("nome")
            .equalTo(user.nome)
            .once("child_added", function (snapshot) {
                const data: QuestionarioResumido = snapshot.val();

                if (data !== null) {
                    if (data.liberado) {
                        history.push(`/acesso-autorizado/${fullDate}/${snapshot.key}`);
                    }
                }
            });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Checkin</div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card card-default">
                        <div className="card-header">Checkin para atividade presencial</div>
                        <div className="card-body">
                            <button className="col-md-4" onClick={() => history.push("/checkin-aluno-aula")}>
                                <div className="card" style={{ marginTop: "16px" }}>
                                    <div className="card-body d-flex align-items-center">
                                        <em className="fa-4x mr-2 fas fa-user-graduate"></em>
                                        <span>Aluno - Aula</span>
                                    </div>
                                </div>
                            </button>
                            <button className="col-md-4" onClick={() => history.push("/checkin-aluno-atividade")}>
                                <div className="card" style={{ marginTop: "16px" }}>
                                    <div className="card-body d-flex align-items-center">
                                        <em className="fa-4x mr-2 fas fa-user-graduate"></em>
                                        <span>Aluno - Outra atividade</span>
                                    </div>
                                </div>
                            </button>
                            <button className="col-md-4" onClick={() => history.push("/checkin-professor")}>
                                <div className="card" style={{ marginTop: "16px" }}>
                                    <div className="card-body d-flex align-items-center">
                                        <em className="fa-4x mr-2 fas fa-chalkboard-teacher"></em>
                                        <span>Professor</span>
                                    </div>
                                </div>
                            </button>
                            <button className="col-md-4" onClick={() => history.push("/checkin-funcionario")}>
                                <div className="card" style={{ marginTop: "16px" }}>
                                    <div className="card-body d-flex align-items-center">
                                        <em className="fa-4x mr-2 fas fa-id-badge"></em>
                                        <span>Funcionário</span>
                                    </div>
                                </div>
                            </button>
                            <button className="col-md-4" onClick={() => history.push("/checkin-terceiro")}>
                                <div className="card" style={{ marginTop: "16px" }}>
                                    <div className="card-body d-flex align-items-center">
                                        <em className="fa-4x mr-2 fas fa-user-cog"></em>
                                        <span>Terceiro</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6"></div>
            </div>
            <div className="row">
                <div className="col-md-6"></div>
            </div>
        </div>
    );
}
