export const instituicao = "MMURAD/FGV";
export const publicURL = "https://triagem.mmurad.com.br";

export type QuestionarioResumido = {
    authorId: string;
    dia: string;
    hora: string;
    id: string;
    liberado: boolean;
    nome: string;
};

export type Usuario = {
    authorId?: string;
    avatar?: string;
    cpf?: string;
    email?: string;
    id: string;
    nome: string;
    telefone?: string;
    tipo?: string;
};

export type Funcionario = Usuario;
export type Terceiro = Usuario;

export type Professor = {
    authorId: string;
    cpf: string;
    hidden: boolean;
    id: string;
    nome: string;
};

export type AlunoType = {
    authorId?: string;
    avatar: string;
    cpf: string;
    id?: string;
    nome: string;
    questionario?: boolean;
    tags?: string[];
    telefone?: string;
    termo?: boolean;
};

export type PesquisaType = {
    authorId: string;
    avatar: string;
    nome: string;
    cpf: string;
    telefone: string;
    presencial: boolean;
    respondidoEm: string;
    removidoPor: string;
};

export type ModuloType = {
    id: string;
    nome: string;
    turma: string;
    professor: string;
    auditorio: string;
    capacidade: string;
    dataAberturaPesquisa: string;
    dataFechamentoPesquisa: string;
    dataAula1: string;
    dataAula2: string;
    dataAula3: string;
    dataAula4: string;
    pesquisas: Array<PesquisaType>;
    alunos: PesquisaType[];
};
