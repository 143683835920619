import { InformacoesPessoais } from "../components/InformacoesPessoais";
import { useAuth } from "../hooks/useAuth";

export function DadosPessoais() {
    const { redirect } = useAuth();

    const handleSubmit = () => {
        redirect();
    };

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Criar conta</div>
            </div>

            <InformacoesPessoais afterSubmit={handleSubmit} />
        </div>
    );
}
