import { ReactNode } from "react";

type Props = {
    children?: ReactNode;
    id: string;
};
export function CheckBox(props: Props) {
    return (
        <div className="checkbox c-checkbox">
            <label>
                <input type="checkbox" id={props.id} />
                <span className="fa-2x fa fa-check"></span> {props.children}
            </label>
        </div>
    );
}
