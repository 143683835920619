import { useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../hooks/useAuth";

export function Logout() {
    const history = useHistory();
    const { signOut } = useAuth();

    const logout = async () => {
        await signOut();

        history.push("/");
    };

    useEffect(() => {
        logout();
    }, []);

    return <></>;
}
