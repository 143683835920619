import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { database } from "../services/firebase";

export function Entrada() {
    const { user } = useAuth();

    const params = useParams<{ dia: string; id: string }>();

    const [nome, setNome] = useState("");
    const [liberado, setLiberado] = useState(false);
    const [dataValida, setDataValida] = useState(true);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (user === undefined) {
            return;
        }

        const ref = database.ref(`questionarios/${params.dia}/${params.id}`);
        ref.once("value", (snapshot) => {
            const data: { dia: string; nome: string; liberado: boolean } = snapshot.val();

            if (null !== data && !loaded) {
                setNome(data.nome);
                setLiberado(data.liberado);

                const dateObj = new Date();
                const fullDate = dateObj.toISOString().substr(0, 10);
                const dia = fullDate.substr(8, 2) + "/" + fullDate.substr(5, 2) + "/" + fullDate.substr(0, 4);
                const hora = ("0" + dateObj.getHours()).slice(-2) + ":" + ("0" + dateObj.getMinutes()).slice(-2);

                if (data.dia !== dia) {
                    setLiberado(false);
                    setDataValida(false);
                } else {
                    ref.child("entradas").push({ dia: dia, hora: hora });
                }
            }

            setLoaded(true);
        });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {liberado && (
                <div className="bg-success-light text-center">
                    <h2>Bem-vindo, {nome}!</h2>
                    <em className="far fa-smile fa-10x"></em>
                </div>
            )}
            {loaded && !liberado && (
                <div className="bg-danger-light text-center">
                    <h1>Lamentamos mas você não deve acessar a instituição</h1>
                    {!dataValida && <h3>Código gerado para uso em uma data diferente</h3>}
                    <em className="far fa-frown fa-10x"></em>
                </div>
            )}
        </>
    );
}
