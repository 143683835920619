import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { instituicao } from "../params";

import "../styles/auth.scss";

export function Login() {
    const { signOut } = useAuth();

    useEffect(() => {
        signOut();
    }, []);

    return (
        <div className="block-center mt-4 wd-xl">
            <div className="card card-flat">
                <div className="card-header text-center">
                    <a href="">
                        <img className="block-center img-fluid" src="/img/logo_header_colorido.png" alt={instituicao} />
                    </a>
                </div>
                <div className="card-body">
                    <p className="pt-3 text-center">Sou aluno {instituicao}</p>
                    <Link to="/login-aluno" className="btn btn-block btn-primary">
                        Entrada de aluno
                    </Link>

                    <p className="pt-3 text-center">É funcionário, professor ou prestador de serviço?</p>
                    <Link to="/login-cpf" className="btn btn-block btn-primary">
                        Entrada de funcionário, professor ou prestador de serviço
                    </Link>
                </div>
            </div>
            <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>2021</span>
                <span className="mx-2">-</span>
                <span>Case Tecnologia</span>
            </div>
        </div>
    );
}
