import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { ModuloType } from "../params";
import { database } from "../services/firebase";

export function useModulos(restrict?: boolean): ModuloType[] {
    const { user } = useAuth();
    const [modulos, setModulos] = useState<ModuloType[]>([]);

    useEffect(() => {
        if (user === undefined) {
            return;
        }

        const ref = database.ref("modulos");

        ref.orderByChild("nome").once("value", (snapshot) => {
            let parsedValues: ModuloType[] = [];

            snapshot.forEach((instance) => {
                console.log("snapshot modulos");
                let modulo: ModuloType = instance.val();
                modulo.id = instance.key || "";
                if (modulo.pesquisas) {
                    let qtdPesquisas = Object.values(modulo.pesquisas || {}).length;
                }
                //if () {
                //hasLiked: Object.values(value.likes ?? {}).some(like => like.authorId === user?.id),
                //likeId: Object.entries(value.likes ?? {}).find(([key, like]) => like.authorId === user?.id)?.[0],
                //}
                if (user) {
                    let participa = Object.values(modulo.pesquisas ?? {}).some((resposta) => resposta.authorId === user?.id);
                    //console.log("userid:" + user?.id);
                    //console.log(modulo.pesquisas);
                    //console.log("participa:" + participa);
                    Object.values(modulo.pesquisas || {}).every((resposta) => {
                        if (!modulo.alunos) {
                            modulo.alunos = [];
                        }
                        modulo.alunos.push(resposta);
                        return "";
                    });
                    if (participa && restrict) parsedValues.push(modulo);
                }
                if (!restrict) {
                    parsedValues.push(modulo);
                }
            });
            setModulos(parsedValues);
        });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return modulos;
}
