import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { database } from "../services/firebase";
import swal from "sweetalert";
import { ModuloType } from "../params";

export function Pesquisa() {
    const { user, checkLogin } = useAuth();

    const [verificando, setVerificando] = useState(true);
    const [encerrado, setEncerrado] = useState(false);
    const [respondido, setRespondido] = useState(false);
    const [presencial, setPresencial] = useState("");
    const params = useParams<{ id: string }>();
    const moduloId = params.id;

    const [modulo, setModulo] = useState<ModuloType>({} as ModuloType);

    useEffect(() => {
        checkLogin();
    }, []);

    function datas(data1: string, data2: string, data3: string, data4: string): string {
        return (
            data1?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data2?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data3?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data4?.replace("/" + new Date().getFullYear(), "")
        );
    }
    async function handleResponder() {
        if (user) {
            const pesquisasRef = database.ref(`modulos/${moduloId}/pesquisas/${user.id}`);

            await pesquisasRef.update({
                avatar: user?.avatar,
                nome: user.nome,
                cpf: user?.cpf,
                telefone: user?.telefone,
                authorId: user.id,
                presencial: "sim" === presencial,
                respondidoEm: new Date().toISOString(),
            });

            setRespondido(true);

            if ("sim" === presencial) {
                await swal({
                    title: "Aviso",
                    text: "Estou ciente que será indispensável a assinatura do documento “Termo de Consentimento”, uma única vez, no prazo estipulado de 02 (dois) dias aí incluído o da data do envio, via Plataforma D4Sign, bem como responder ao “Questionário Autodeclaratório de Saúde”, antes de acessar as dependências da Instituição,  a cada dia de aula da disciplina.",
                    //@ts-ignore
                    button: "Ciente!",
                });
            }

            swal("Sucesso!", "Opção registrada com sucesso. Agradecemos pelo envio da sua resposta!", "success");
        }
    }
    function handleAtivarNotificacoes() {}

    function handleGerarTermo() {
        const cofre = "3f3b2812-988a-4732-8304-2ff6370b6829";
        const url1 = `https://secure.d4sign.com.br/api/v1/documents/${cofre}/makedocumentbytemplate?tokenAPI=live_273748a686c4134ac8bce730db84094e6f99395588fa15a3b98e34c93a4e2875&cryptKey=live_crypt_pErkNe7jhXWBydynOA6sLK7Z1Dot5C2k`;
        (async () => {
            const rawResponse = await fetch(url1, {
                credentials: "include",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name_document: "Termo de Consentimento - teste",
                    uuid_folder: cofre,
                    templates: {
                        "6117": {
                            nomecompleto: "teste",
                        },
                    },
                }),
            });
            const content = await rawResponse.json();

            console.log(content);
        })();
    }

    useEffect(() => {
        if (user === undefined) {
            return;
        }

        const ref = database.ref(`modulos/${moduloId}`);
        ref.once("value", (snapshot) => {
            const modulo: ModuloType = snapshot.val();
            setModulo(modulo);

            const encerramento =
                modulo.dataFechamentoPesquisa.substring(6, 10) +
                "-" +
                modulo.dataFechamentoPesquisa.substring(3, 5) +
                "-" +
                modulo.dataFechamentoPesquisa.substring(0, 2);

            fetch("https://worldtimeapi.org/api/timezone/America/Sao_Paulo")
                .then((response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            setVerificando(false);

                            if (encerramento < data?.datetime.substring(0, 10)) {
                                setEncerrado(true);
                            }
                        });
                    }
                })
                .catch(() => {
                    setVerificando(false);
                    const clientDate = new Date().toISOString().split("T")[0];

                    if (encerramento < clientDate) {
                        setEncerrado(true);
                    }
                });
        });

        return () => {
            ref.off("value");
        };
    }, [moduloId, user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            <div className="content-heading">Pesquisa de participação</div>
            <div className="row">
                {!modulo && (
                    <div className="col-12">
                        <div className="alert alert-danger fade show" role="alert">
                            Pesquisa não encontrada!
                        </div>
                    </div>
                )}
                {modulo && (
                    <div className="col-xl-6 col-lg-6">
                        <div className="card b">
                            <div className="card-header">
                                <div className="float-right">{/*<div className={`badge badge-info ${props.status}`}>{props.status}</div>*/}</div>
                                <h4 className="m-0">{modulo.nome}</h4>
                                <small className="text-muted">{modulo.turma}</small>
                            </div>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Datas</strong>
                                        </td>
                                        <td>{datas(modulo.dataAula1, modulo.dataAula2, modulo.dataAula3, modulo.dataAula4)}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Professor</strong>
                                        </td>
                                        <td>{modulo.professor}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ borderRadius: "0" }}>
                                        <label className="c-radio mb-0">
                                            <input
                                                id="inlineradio1"
                                                type="radio"
                                                name="inlineradio1"
                                                value="nao"
                                                onChange={(event) => setPresencial(event.target.value)}
                                                disabled={respondido}
                                            />
                                            <span className="fa fa-circle"></span>
                                        </label>
                                    </span>
                                </div>
                                <p className="form-control" style={{ border: "none", height: "auto" }}>
                                    Prefiro continuar assistindo as aulas de forma remota (via Zoom);
                                </p>
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ borderRadius: "0" }}>
                                        <label className="c-radio mb-0">
                                            <input
                                                id="inlineradio1"
                                                type="radio"
                                                name="inlineradio1"
                                                value="sim"
                                                onChange={(event) => setPresencial(event.target.value)}
                                                disabled={respondido}
                                            />
                                            <span className="fa fa-circle"></span>
                                        </label>
                                    </span>
                                </div>
                                <p className="form-control" style={{ border: "none", height: "auto" }}>
                                    Gostaria de assistir as aulas presenciais na MMurad, se possível, todos os 4 dias.
                                </p>
                            </div>
                            {!respondido && !encerrado && !verificando && (
                                <div className="card-footer text-center">
                                    <button className="btn btn-secondary" type="button" onClick={handleResponder} disabled={presencial === ""}>
                                        Responder
                                    </button>
                                </div>
                            )}
                            {respondido && !encerrado && !verificando && (
                                <div className="card-footer text-center bg-success">
                                    <button className="btn btn-secondary" style={{ marginRight: "6px" }} type="button" onClick={handleAtivarNotificacoes}>
                                        Ativar Notificações
                                    </button>

                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        onClick={(event) => {
                                            setRespondido(false);
                                        }}>
                                        Alterar
                                    </button>
                                </div>
                            )}
                            {encerrado && (
                                <div className="card-footer text-center bg-danger-light">
                                    <p>Pesquisa encerrada</p>
                                </div>
                            )}
                            {verificando && (
                                <div className="card-footer text-center">
                                    <p>Verificando datas</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
