import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useAuth } from "../hooks/useAuth";
import firebase from "firebase";

import "../styles/auth.scss";

export function LoginCpf() {
    const { user, redirect, signInWithCpf } = useAuth();

    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");

    const [showEmail, setShowEmail] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    function validateOnChange(event: ChangeEvent) {
        /*const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });*/
    }

    async function handleCpf(event: FormEvent) {
        /*const form = event.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        console.log(hasError ? 'Form has errors. Check!' : 'Form Submitted!')*/

        event.preventDefault();

        console.log(user);

        await signInWithCpf(cpf);
        console.log(user);

        if (!user) {
            setShowEmail(true);
        }
    }

    async function handleEmail(event: FormEvent) {
        event.preventDefault();
        localStorage.setItem("cpf", cpf);

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: "https://triagem.mmurad.com.br/login-email",
            // This must be true.
            handleCodeInApp: true,
        };

        firebase
            .auth()
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem("emailForSignIn", email);
                setShowEmail(false);
                setShowMessage(true);
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
            });
    }

    /* Simplify error check */
    function hasError(formName: string, inputName: string, method: string): boolean {
        return false;
        /*return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]*/
    }

    useEffect(() => {
        redirect();
        Inputmask().mask(document.querySelectorAll("#cpf"));
    }, [user?.id]);

    return (
        <div className="block-center mt-4 wd-xl">
            <div className="card card-flat">
                <div className="card-header text-center">
                    <a href="">
                        <img className="block-center img-fluid" src="/img/logo_header_colorido.png" alt="Mmurad/FGV" />
                    </a>
                </div>
                <div className="card-body">
                    <p className="text-center py-2">Funcionário, professor ou prestador de serviço</p>
                    {!showEmail && !showMessage && (
                        <form className="mb-3" name="formLogin" onSubmit={handleCpf}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input
                                        type="text"
                                        id="cpf"
                                        name="cpf"
                                        className="border-right-0"
                                        placeholder="Digite o seu CPF"
                                        invalid={hasError("formLogin", "cpf", "required")}
                                        onChange={(event) => {
                                            setCpf(event.target.value);
                                        }}
                                        data-validate='["required", "cpf"]'
                                        data-inputmask="'mask': '999.999.999-99'"
                                        defaultValue={cpf}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="far fa-id-card"></em>
                                        </span>
                                    </div>
                                    {hasError("formLogin", "email", "required") && <span className="invalid-feedback">Field is required</span>}
                                    {hasError("formLogin", "email", "email") && <span className="invalid-feedback">Field must be valid email</span>}
                                </div>
                            </div>
                            <button className="btn btn-block btn-primary mt-3" type="submit">
                                Entrar
                            </button>
                        </form>
                    )}
                    {showEmail && (
                        <form className="mb-3" name="formEmail" onSubmit={handleEmail}>
                            <div className="alert alert-primary">CPF não cadastrado</div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Digite o seu e-mail"
                                        invalid={hasError("formEmail", "email", "required")}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                        data-validate='["required"]'
                                        defaultValue={email}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="far fa-envelope"></em>
                                        </span>
                                    </div>
                                    {hasError("formEmail", "email", "required") && <span className="invalid-feedback">Field is required</span>}
                                    {hasError("formEmail", "email", "email") && <span className="invalid-feedback">Field must be valid email</span>}
                                </div>
                            </div>
                            <button className="btn btn-block btn-primary mt-3" type="submit">
                                Entrar
                            </button>
                        </form>
                    )}
                    {showMessage && <div>Para continuar, siga o link que você vai receber no e-mail {email}</div>}
                </div>
            </div>
            <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>2021</span>
                <span className="mx-2">-</span>
                <span>Case Tecnologia</span>
            </div>
        </div>
    );
}
