import { FormEvent, useEffect, useState } from "react";
import { FormControl, FormLabel } from "react-bootstrap";
import { Button, Form, FormGroup } from "reactstrap";
import { useAuth } from "../hooks/useAuth";
import { database } from "../services/firebase";
import swal from "sweetalert";

type InfProps = {
    afterSubmit?: any;
    onSubmit?: any;
};
export function InformacoesPessoais(props: InfProps) {
    const { user, checkLogin } = useAuth();

    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (user && user.id) {
            user.cpf = cpf;
            user.email = email;
            user.nome = nome;
            user.telefone = telefone;
            
            if ("terceiro" === user.tipo) {
                console.log("atualizando terceiro");
                const userRef = database.ref(`/terceiros/${user.id}`);
                await userRef.update(
                    {
                        nome: nome,
                        cpf: cpf,
                        email: email,
                        telefone: telefone,
                        authorId: user.id,
                    },
                    async (error) => {
                        if (error) {
                            console.log(error);
                            await swal("Erro!", "Não foi possível atualizar as informações pessoais!", "error");
                        } else {
                            await swal("Sucesso!", "Informações atualizadas com sucesso!", "success");

                            if (typeof props.afterSubmit === "function") {
                                props.afterSubmit(user.id, nome, cpf, email, telefone);
                            }
                        }
                    }
                );
            } else {
                console.log("atualizando usuario");
                const userRef = database.ref(`/usuarios/${user.id}`);
                await userRef.update(
                    {
                        nome: nome,
                        cpf: cpf,
                        email: email,
                        telefone: telefone,
                        avatar: user?.avatar,
                        authorId: user.id,
                    },
                    async (error) => {
                        if (error) {
                            console.log(error);
                            await swal("Erro!", "Não foi possível atualizar as informações pessoais!", "error");
                        } else {
                            await swal("Sucesso!", "Informações atualizadas com sucesso!", "success");

                            if (typeof props.afterSubmit === "function") {
                                props.afterSubmit(user.id, nome, cpf, email, telefone);
                            }
                        }
                    }
                );
            }
        }
    }

    useEffect(() => {
        console.log(user);
        if (user) {
            setNome(user.nome);
            setCpf(user.cpf || "");
            setEmail(user.email || "");
            setTelefone(user.telefone || "");

            checkLogin();
        }
    }, [user?.id]);

    useEffect(() => {
        Inputmask().mask(document.querySelectorAll("#cpf, #telefone"));
    });

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="card card-default">
                    <div className="card-header">
                        <strong>INFORMAÇÕES PESSOAIS</strong>
                    </div>
                    <div className="card-body">
                        <Form
                            onSubmit={(event) => {
                                if (typeof props.onSubmit === "function") {
                                    props.onSubmit(event, nome, cpf, email, telefone);
                                } else {
                                    handleSubmit(event);
                                }
                            }}>
                            <FormGroup>
                                <FormLabel htmlFor="nome">Nome completo</FormLabel>
                                <FormControl id="nome" onChange={(event) => setNome(event.target.value)} defaultValue={nome} required></FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="cpf">CPF</FormLabel>
                                <FormControl
                                    id="cpf"
                                    data-inputmask="'mask': '999.999.999-99'"
                                    onChange={(event) => setCpf(event.target.value)}
                                    value={cpf}
                                    required></FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="email">E-mail</FormLabel>
                                <FormControl id="email" onChange={(event) => setEmail(event.target.value)} defaultValue={email} required></FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="telefone">Telefone</FormLabel>
                                <FormControl
                                    id="telefone"
                                    data-inputmask="'mask': '(99) 9999[9]-9999'"
                                    onChange={(event) => setTelefone(event.target.value)}
                                    value={telefone}
                                    required></FormControl>
                            </FormGroup>
                            <Button type="submit">Salvar</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
