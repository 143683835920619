import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { toggleSetting } from "../../store/actions/actions";

import HeaderSearch from "./HeaderSearch";

type HeaderProps = {
    toggleSetting: typeof toggleSetting;
};

class Header extends Component<HeaderProps> {
    state = {
        navSearchOpen: false,
    };

    toggleUserblock = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting("showUserBlock");
    };

    toggleOffsidebar = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting("offsidebarOpen");
    };

    toggleCollapsed = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting("isCollapsed");
        this.resize();
    };

    toggleAside = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting("asideToggled");
    };

    resize() {
        const event = document.createEvent("HTMLEvents");
        event.initEvent("resize", true, false);
        document.dispatchEvent(event);
    }

    toggleNavSearch: React.MouseEventHandler = (e) => {
        e.preventDefault();
        this.setState({
            navSearchOpen: !this.state.navSearchOpen,
        });
    };

    closeNavSearch: React.EventHandler<any> = (e) => {
        e.preventDefault();
        this.setState({
            navSearchOpen: false,
        });
    };

    render() {
        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="/img/logo_header_colorido.png" alt="Mmurad/FGV" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="/img/favicon.png" alt="MMurad/FGV" height="38" width="190" />
                            </div>
                        </a>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        {/* START User avatar toggle */}
                        {/* <li className="nav-item d-none d-md-block">
                            <a  className="nav-link" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li> */}
                        {/* END User avatar toggle */}
                        {/* START lock screen */}
                        {/* <li className="nav-item d-none d-md-block">
                            <Link to="lock" title="Lock screen" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li> */}
                        {/* END lock screen */}
                    </ul>
                    {/* END Left navbar */}
                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {/* Search icon */}
                        {/* <li className="nav-item">
                            usuario
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/logout">
                                <em className="icon-logout"></em>
                            </Link>
                        </li>
                    </ul>
                    {/* END Right Navbar */}
                    {/* START Search form */}
                    <HeaderSearch isOpen={this.state.navSearchOpen} onClose={this.closeNavSearch} />
                    {/* END Search form */}
                </nav>
                {/* END Top Navbar */}
            </header>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ toggleSetting: bindActionCreators(toggleSetting, dispatch) });

export default connect(null, mapDispatchToProps)(Header);
