import { createContext, ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Usuario } from "../params";
import { auth, database, firebase } from "../services/firebase";

type AuthContextType = {
    user: Usuario | undefined;
    checkLogin: () => void;
    redirect: () => void;
    signInWithEmail: (cpf: string, email: string) => void;
    signInWithGoogle: () => Promise<void>;
    signInWithFacebook: () => Promise<void>;
    signInWithCpf: (cpf: string) => Promise<void>;
    signOut: () => Promise<void>;
    cadastraTerceiro: (cpf: string, email: string, nome: string, telefone: string) => Promise<void>;
};

type AuthContextProviderProps = {
    children: ReactNode;
};

export function AuthContextProvider(props: AuthContextProviderProps) {
    const history = useHistory();
    const location = useLocation<{ destination: string }>();

    const [user, setUser] = useState<Usuario>();

    async function loadLocalData(userId: string): Promise<boolean> {
        console.log("loading additional data");
        let result = false;

        await database.ref(`usuarios/${userId}`).once("value", (snapshot) => {
            const data: Usuario = snapshot.val();
            console.log(data);

            if (data) {
                setUser({
                    ...data,
                    id: userId,
                    tipo: "aluno",
                });

                result = true;
            }
        });

        return result;
    }

    async function loadLocalDataFor(node: string, usertype: string, cpf: string): Promise<boolean> {
        let result = false;

        await database
            .ref(node)
            .orderByChild("cpf")
            .equalTo(cpf)
            .once("value", function (snapshot) {
                snapshot.forEach((instance) => {
                    const data: { authorId: string; nome: string; avatar: string; cpf: string; email: string; telefone: string; tipo: string } = instance.val();

                    if (data) {
                        console.log(usertype);

                        localStorage.setItem("logado", "sim");

                        console.log(data);
                        setUser({
                            id: data.authorId,
                            nome: data.nome,
                            avatar: data.avatar,
                            cpf: data.cpf,
                            email: data.email,
                            telefone: data.telefone,
                            tipo: data.tipo ? data.tipo : usertype,
                        });

                        result = true;
                    }
                });
            });

        return result;
    }

    const onAuthStateChanged = async (user: any) => {
        if (user) {
            const { displayName, email, phoneNumber, photoURL, uid } = user;

            let providerName = user.providerData[0].providerId || "";

            // if (!displayName || !photoURL) {
            //     throw new Error("Missing information from External Account.");
            // }

            localStorage.setItem("logado", "sim");

            if (providerName === "google.com" || providerName === "facebook.com") {
                // Tenta carregar dado de aluno cadastrado
                let ret = await loadLocalData(uid);

                if (!ret) {
                    console.log("Aluno não cadastrado");
                    setUser({
                        id: uid,
                        nome: displayName,
                        avatar: photoURL,
                        cpf: "",
                        email: email,
                        telefone: phoneNumber,
                        tipo: "aluno",
                    });
                }
            } else {
                setUser({
                    id: uid,
                    nome: displayName,
                    avatar: photoURL,
                    cpf: localStorage.getItem("cpf") || "",
                    email: email,
                    telefone: phoneNumber,
                    tipo: "terceiro",
                });
            }
        } else {
            setUser(undefined);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(onAuthStateChanged);

        return () => {
            unsubscribe();
        };
    }, []);

    async function signInWithGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider();

        await auth.signInWithPopup(provider);
    }

    async function signInWithFacebook() {
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope("email");

        await auth.signInWithPopup(provider);
    }

    async function signInWithCpf(cpf: string) {
        let found = await loadLocalDataFor("funcionarios", "funcionario", cpf);

        if (!found) {
            found = await loadLocalDataFor("professores", "professor", cpf);
        }

        if (!found) {
            found = await loadLocalDataFor("terceiros", "terceiro", cpf);
        }
    }

    async function signInWithEmail(cpf: string, email: string) {
        let found = await loadLocalDataFor("terceiros", "terceiro", cpf);

        if (!found) {
            await cadastraTerceiro(cpf, email, "", "");
            signInWithEmail(cpf, email);
        }
    }

    async function cadastraTerceiro(cpf: string, email: string, nome: string, telefone: string) {
        const userRef = await database.ref("terceiros").push({ cpf: cpf, email: email, nome: nome, telefone: telefone });

        if (null !== userRef.key) {
            setUser({
                id: userRef.key,
                nome: nome,
                avatar: "",
                cpf: cpf,
                email: email,
                telefone: telefone,
                tipo: "terceiro",
            });
        }
    }

    async function signOut() {
        if (user && user.id) {
            database.ref(`usuarios/${user.id}`).off();
        }

        localStorage.removeItem("logado");

        setUser(undefined);

        await firebase.auth().signOut();
    }

    function checkLogin() {
        console.log("checkLogin");
        if ("sim" !== localStorage.getItem("logado")) {
            if ("/" !== location.pathname && "/login" !== location.pathname.slice(0, 6)) {
                localStorage.setItem("destination", location.pathname);
            }

            history.push("/login");
        }
    }

    function redirect() {
        console.log(user);
        if (user) {
            if (user && (user.tipo === "admin" || user.tipo === "recepcao")) {
                let destination = localStorage.getItem("destination");
                localStorage.removeItem("destination");

                if ("/login" !== destination?.slice(0, 6)) {
                    history.push(destination || "/checkin-funcionario");
                }
            } else if (user && user.tipo === "aluno") {
                console.log(user.cpf);
                if (user.cpf) {
                    let destination = localStorage.getItem("destination");
                    localStorage.removeItem("destination");
                    if ("/login" !== destination?.slice(0, 6)) {
                        history.push(destination || "/checkin-aluno-aula");
                    }
                } else {
                    history.push("/dados-pessoais");
                }
            } else if (user) {
                if (user.nome) {
                    let destination = localStorage.getItem("destination");
                    localStorage.removeItem("destination");
                    if ("/login" !== destination?.slice(0, 6)) {
                        history.push(destination || "/checkin-" + user.tipo);
                    }
                } else {
                    history.push("/dados-pessoais");
                }
            }
        }
    }

    return (
        <AuthContext.Provider
            value={{ cadastraTerceiro, checkLogin, redirect, signInWithEmail, signInWithGoogle, signInWithFacebook, signInWithCpf, signOut, user }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export const AuthContext = createContext({} as AuthContextType);
