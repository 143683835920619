export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
    restrict?: boolean;
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
    {
        heading: "Menu",
        translate: "sidebar.heading.HEADER",
    },
    {
        name: "Início",
        path: "/home",
        icon: "icon-grid",
    },
    {
        name: "Check-in",
        path: "/checkin",
        icon: "fas fa-door-open",
    },
    {
        name: "Módulos",
        path: "/modulos",
        icon: "far fa-calendar-alt",
        restrict: true,
    },
    {
        name: "Funcionários",
        path: "/funcionarios",
        icon: "far fa-id-badge",
        restrict: true,
    },
    {
        name: "Professores",
        path: "/professores",
        icon: "fas fa-chalkboard-teacher",
        restrict: true,
    },
    {
        name: "Terceiros",
        path: "/terceiros",
        icon: "fas fa-user-cog",
        restrict: true,
    },
    {
        name: "Relatórios",
        path: "/relatorios",
        icon: "icon-grid",
        restrict: true,
    },
    {
        name: "Auditoria",
        icon: "fas fa-history",
        translate: "sidebar.nav.COMPONENTS",
        label: { value: 4, color: "info" },
        restrict: true,
        submenu: [
            {
                name: "Buttons",
                path: "buttons",
            },
            {
                name: "Forms",
                path: "form-standard",
            },
            {
                name: "Tables",
                path: "table-standard",
            },
            {
                name: "Cards",
                path: "cards",
            },
        ],
    },
];

export default Menu;
