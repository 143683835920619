import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { QuestionarioResumido } from "../params";
import { database } from "../services/firebase";

export function AcessoNegado() {
    const { user } = useAuth();

    const params = useParams<{ dia: string; id: string }>();

    const [dia, setDia] = useState("");
    const [hora, setHora] = useState("");
    const [liberado, setLiberado] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [nome, setNome] = useState("");

    useEffect(() => {
        if (user === undefined) {
            setLoaded(false);
            return;
        }

        const ref = database.ref(`questionarios/${params.dia}/${params.id}`);
        ref.once("value", (snapshot) => {
            const data: QuestionarioResumido = snapshot.val();

            if (null !== data) {
                setDia(data.dia);
                setHora(data.hora);
                setLiberado(data.liberado);
                setLoaded(true);
                setNome(data.nome);
            }
        });

        return () => {
            ref.off();
        };
    }, [params, user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            {loaded && !liberado && (
                <div className="col-md-12 text-center">
                    <p>
                        Obrigado por responder ao questionário do dia {dia} às {hora}, {nome}
                    </p>
                    <p>
                        Este questionário não é um diagnóstico clínico ou tratamento de doenças ou outras condições, porém sinaliza que você apresentou sintomas
                        ou condições de saúde que priorizam SUA PERMANÊNCIA EM CASA por prevenção; nesse caso, acompanhe sua disciplina ofertada de forma
                        remota, via Plataforma ZOOM. Recomendamos que você procure atendimento médico para avaliar suas necessidades de saúde e/ou seu retorno
                        às atividades em grupo. Siga as Orientações do profissional de saúde e monitore seus sintomas regularmente.
                    </p>
                </div>
            )}
        </div>
    );
}
