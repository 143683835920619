import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

import "../styles/auth.scss";

export function LoginAluno() {
    const { user, redirect, signInWithGoogle, signInWithFacebook } = useAuth();

    async function handleGoogle() {
        console.log("signInWithGoogle");
        await signInWithGoogle();
    }

    async function handleFacebook() {
        console.log("signInWithFacebook");
        await signInWithFacebook();
    }

    useEffect(() => {
        redirect();
    }, [user?.id]);

    return (
        <div className="block-center mt-4 wd-xl">
            <div className="card card-flat">
                <div className="card-header text-center">
                    <a href="">
                        <img className="block-center img-fluid" src="/img/logo_header_colorido.png" alt="Mmurad/FGV" />
                    </a>
                </div>
                <div className="card-body">
                    <p className="text-center py-2">Prezado aluno, faça login para continuar</p>

                    <button className="btn btn-block btn-primary mt-3 google" onClick={handleGoogle}>
                        <em className="fa-2x mr-2 fab fa-google"></em>
                        Entrar com uma conta do Google
                    </button>
                    <button className="btn btn-block btn-primary mt-3 facebook" onClick={handleFacebook}>
                        <em className="fa-2x mr-2 fab fa-facebook"></em>
                        Entrar com uma conta do Facebook
                    </button>
                </div>
            </div>
            <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>2021</span>
                <span className="mx-2">-</span>
                <span>Case Tecnologia</span>
            </div>
        </div>
    );
}
