import { useEffect } from "react";
import { useHistory } from "react-router";
import { InformacoesPessoais } from "../components/InformacoesPessoais";
import { Questionario } from "../components/Questionario";
import { useAuth } from "../hooks/useAuth";
import { QuestionarioResumido } from "../params";
import { database } from "../services/firebase";

export function CheckinProfessor() {
    const history = useHistory();
    const { user } = useAuth();

    useEffect(() => {
        if (user === undefined || user.id === undefined) {
            return;
        }

        const fullDate = new Date().toISOString().substr(0, 10);

        const ref = database.ref(`questionarios/${fullDate}`);
        ref.orderByChild("nome")
            .equalTo(user.nome)
            .once("child_added", function (snapshot) {
                const data: QuestionarioResumido = snapshot.val();

                if (data !== null) {
                    if (data.liberado) {
                        history.push(`/acesso-autorizado/${fullDate}/${snapshot.key}`);
                    }
                }
            });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Checkin de Professor</div>
            </div>

            <InformacoesPessoais />
            <div className="row">
                <div className="col-md-6">
                    <div className="card card-default">
                        <div className="card-header">
                            <strong>QUESTIONÁRIO AUTODECLARATÓRIO DE SAÚDE</strong>
                        </div>
                        <div className="card-body">
                            <Questionario></Questionario>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
