import { ReactNode } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
//import Offsidebar from "./Offsidebar";
import { Footer } from "./Footer";

export function Base(props: { children?: ReactNode }) {
    return (
        <div className="wrapper">
            <Header />

            <Sidebar />

            {/*<Offsidebar />*/}

            <section className="section-container">{props.children}</section>

            <Footer />
        </div>
    );
}
