import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { QuestionarioResumido } from "../params";
import { database } from "../services/firebase";

export function ListarQuestionarios() {
    const { user } = useAuth();

    const params = useParams<{ userId: string }>();

    const [questionarios, setQuestionarios] = useState<QuestionarioResumido[]>([]);

    useEffect(() => {
        if (user && user.id) {
            database
                .ref("questionarios")
                .orderByChild("*/authorId")
                .equalTo(params.userId)
                .once("value", function (snapshot) {
                    console.log("snapshot terceiros");
                    let parsedValues: QuestionarioResumido[] = [];

                    snapshot.forEach((instance) => {
                        let questionario: QuestionarioResumido = instance.val();
                        questionario.id = instance.key || "";

                        parsedValues.push(questionario);
                    });

                    setQuestionarios(parsedValues);
                });
        }
    }, []);

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                <div>Questionários por usuário</div>
            </div>

            {(user === undefined || (user.tipo !== "admin" && user.tipo !== "recepcao")) && (
                <div className="row">
                    <div className="col-12">
                        <div className="denied alert alert-danger fade show" role="alert">
                            Acesso negado!
                        </div>
                    </div>
                </div>
            )}
            {user && (user.tipo === "admin" || user.tipo === "recepcao") && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-default">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover" id="table-ext-1">
                                        <thead>
                                            <tr>
                                                <th>Autor</th>
                                                <th>Nome completo</th>
                                                <th>Dia</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {questionarios &&
                                                questionarios.map((questionario, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{questionario.authorId}</td>
                                                            <td>{questionario.nome}</td>
                                                            <td>{questionario.dia}</td>
                                                            
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
