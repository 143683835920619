import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { ModuloType } from "../params";
import { database } from "../services/firebase";

export function Hoje() {
    const history = useHistory();
    const { user } = useAuth();

    const [encerrado, setEncerrado] = useState(false);
    const [respondido, setRespondido] = useState(false);
    const params = useParams<{ id: string }>();
    const moduloId = params.id;
    const [modulo, setModulo] = useState<ModuloType>({} as ModuloType);
    const [data, setData] = useState("");

    async function handleResponder() {
        setRespondido(true);

        if (user && user.tipo === "admin") {
            history.push("/checkin-funcionario");
        } else if (user) {
            history.push(`/checkin-${user.tipo}`);
        }
    }
    function handleAtivarNotificacoes() {}

    useEffect(() => {
        if (user === undefined) {
            return;
        }

        const pathName = window.location.pathname;

        // if (!user) {
        //     history.push({ pathname: "/login-cpf", state: { destination: pathName } });
        // }
        const dateObj = new Date();
        const fullDate = dateObj.toISOString().substr(0, 10);
        const dia = fullDate.substr(8, 2) + "/" + fullDate.substr(5, 2) + "/" + fullDate.substr(0, 4);
        setData(dia);

        const ref = database.ref(`modulos/${moduloId}`);

        if (user && !user.tipo) {
            ref.once("value", (snapshot) => {
                const modulo: ModuloType = snapshot.val();
                setModulo(modulo);

                // fetch("http://worldtimeapi.org/api/timezone/America/Sao_Paulo").then((response) => {
                //     if (200 === response.status) {
                //         response.json().then((data) => {
                //             setData(data?.datetime.substring(8, 10) + "/" + data?.datetime.substring(5, 7) + "/" + data?.datetime.substring(0, 4));
                //         });
                //     }
                // });
            });
        }

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-wrapper">
            <div className="content-heading">Hoje</div>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="card b">
                        <div className="card-header">
                            <div className="float-right">{/*<div className={`badge badge-info ${props.status}`}>{props.status}</div>*/}</div>
                            <h4 className="m-0">{data}</h4>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Nome</strong>
                                    </td>
                                    <td>{user?.nome}</td>
                                </tr>
                            </tbody>
                        </table>

                        {!respondido && !encerrado && (
                            <div className="card-footer text-center">
                                <button className="btn btn-secondary" type="button" onClick={handleResponder}>
                                    Responder
                                </button>
                            </div>
                        )}
                        {respondido && !encerrado && (
                            <div className="card-footer text-center bg-success">
                                <button className="btn btn-secondary" style={{ marginRight: "6px" }} type="button">
                                    Ativar Notificações
                                </button>
                                <button className="btn btn-secondary" style={{ marginRight: "6px" }} type="button">
                                    Assinar termo
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={(event) => {
                                        setRespondido(false);
                                    }}>
                                    Alterar
                                </button>
                            </div>
                        )}
                        {encerrado && (
                            <div className="card-footer text-center bg-danger-light">
                                <p>Pesquisa encerrada</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
