import { CheckBox } from "../components/CheckBox";
import { Boolean } from "../components/Boolean";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { FormEvent } from "react";
import { database } from "../services/firebase";
import { useAuth } from "../hooks/useAuth";
import swal from "sweetalert";

export function Questionario() {
    const { user } = useAuth();
    const history = useHistory();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (hasError()) {
            swal("Erro!", "Por favor responda a todas as questões e marque as duas caixas de verificação", "error");
            return;
        }

        let acessoNegado = false;
        let respostas = {} as any;

        const questoes = [
            "febre",
            "tosse",
            "dordegarganta",
            "congestaonasal",
            "faltadear",
            "dordecabeca",
            "dornocorpo",
            "conjuntivite",
            "diarreia",
            "perdapaladar",
            "perdaolfato",
            "nausea",
            "testepositivo",
            "contatosuspeita",
            "testesemresultado",
            "gruporisco",
        ];

        questoes.forEach((element) => {
            let inputElement = document.forms[2][element];

            if (inputElement.checked === undefined) {
                // Radio Button
                acessoNegado = acessoNegado || inputElement.value !== "nao";
                respostas[element] = inputElement.value !== "nao";
            } else {
                // Checkbox
                acessoNegado = acessoNegado || inputElement.checked;
                respostas[element] = inputElement.checked;
            }
        });
        console.log(respostas);

        const dateObj = new Date();
        const fullDate = dateObj.toISOString().substr(0, 10);
        const dia = fullDate.substr(8, 2) + "/" + fullDate.substr(5, 2) + "/" + fullDate.substr(0, 4);
        const hora = ("0" + dateObj.getHours()).slice(-2) + ":" + ("0" + dateObj.getMinutes()).slice(-2);

        const newRef = await database.ref(`questionarios/${fullDate}`).push({
            dia: dia,
            hora: hora,
            authorId: user?.id || "",
            nome: user?.nome,
            liberado: !acessoNegado,
            ...respostas,
        });

        if (acessoNegado) {
            history.push(`/acesso-negado/${fullDate}/${newRef.key}`);
        } else {
            history.push(`/acesso-autorizado/${fullDate}/${newRef.key}`);
        }
    };

    const hasError = (): boolean => {
        let respostas = true;

        let permissaouso = document.getElementById("permissaouso") as HTMLInputElement;
        let ciente = document.getElementById("ciente") as HTMLInputElement;

        const questoes = ["febre", "testepositivo", "contatosuspeita", "testesemresultado", "gruporisco"];

        questoes.forEach((element) => {
            let inputElement = document.forms[2][element];

            if (inputElement && "" === inputElement.value) {
                respostas = false;
                return;
            }
        });

        return !(respostas && permissaouso && permissaouso.checked && ciente && ciente.checked);
    };

    return (
        <>
            <p>Requisitamos que você responda ao questionário de triagem antes de se dirigir à MMurad/FGV</p>
            <p>
                Este questionário não tem a finalidade de realizar diagnóstico clínico ou tratamento de doenças ou outras condições e é específico para os
                locais da Instituição.
            </p>
            <form id="questionario" name="questionario">
                <fieldset></fieldset>
                <p>1. Você apresenta ou apresentou febre (37,5°C ou mais) nos últimos 3 dias?</p>
                <Boolean id="febre"></Boolean>
                <p>2. Você apresenta ou apresentou, nos últimos 3 dias, algum dos sintomas a seguir? Se sim, selecione quais.</p>
                <p>Caso qualquer um dos sintomas abaixo seja assinalado, a pessoa estará impossibilitada de acessar às instalações da Instituição.</p>
                <CheckBox id="tosse">Tosse</CheckBox>
                <CheckBox id="dordegarganta">Dor de garganta</CheckBox>
                <CheckBox id="congestaonasal">Congestão nasal</CheckBox>
                <CheckBox id="faltadear">Falta de ar</CheckBox>
                <CheckBox id="dordecabeca">Dor de cabeça</CheckBox>
                <CheckBox id="dornocorpo">Dor no corpo</CheckBox>
                <CheckBox id="conjuntivite">Conjuntivite</CheckBox>
                <CheckBox id="diarreia">Diarréia</CheckBox>
                <CheckBox id="perdapaladar">Perda de paladar</CheckBox>
                <CheckBox id="perdaolfato">Perda do olfato</CheckBox>
                <CheckBox id="nausea">Náusea ou vômito</CheckBox>
                <p>3. Você foi diagnosticado com resultado laboratorial confirmado para COVID-19 nos últimos 14 dias? (mesmo não tendo apresentado sintomas)</p>
                <Boolean id="testepositivo"></Boolean>
                <p>
                    4. Nos últimos 14 dias, você teve contato próximo ou domiciliar com uma pessoa suspeita ou com diagnóstico positivo para COVID-19 (ou seja,
                    a menos de 1,5 metros por mais de 15 minutos em 1 dia, mesmo que essa pessoa não tenha apresentado sintomas)?
                </p>
                <Boolean id="contatosuspeita"></Boolean>
                <p>5. Você fez o teste viral (RT-PCR) para COVID-19 e está aguardando o resultado?</p>
                <Boolean id="testesemresultado"></Boolean>
                <p>6. Você faz parte do grupo de riscos e não tenha recebido nenhuma dose da vacina?</p>
                <Boolean id="gruporisco"></Boolean>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <CheckBox id="permissaouso">
                        Declaro que as informações, por mim fornecidas, são verídicas e que poderão ser utilizadas, durante a triagem, para permissão da minha
                        entrada nas dependências da MMurad/FGV. Estado febril ou com sintomas compatíveis com da COVID-19, restringirão meu acesso ao
                        estabelecimento, conforme os dispositivos e normas sanitárias. Concordo, ainda, que os meus dados sejam arquivados e consultados apenas
                        pelo pessoal autorizado e conforme a Lei 13.709/2018 (Lei Geral de Proteção de Dados Pessoais).
                    </CheckBox>
                </div>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <CheckBox id="ciente">
                        Estou ciente que este Questionário é autodeclaratório e que deve ser respondido antes de acessar as dependências, a cada entrada na
                        Instituição, com o objetivo de identificar casos suspeitos de COVID-19.
                    </CheckBox>
                </div>
                {user && (
                    <Button type="submit" onClick={handleSubmit}>
                        Enviar
                    </Button>
                )}
            </form>
        </>
    );
}
