import { useHistory } from "react-router";
import { ResumoModulo } from "../components/ResumoModulo";
import { useAuth } from "../hooks/useAuth";
import { useModulos } from "../hooks/useModulos";

export function Modulos() {
    const { user } = useAuth();
    const history = useHistory();
    const modulos = useModulos(false);

    function handleCadastrar() {
        history.push("/cadastrar-modulo");
    }

    function datas(data1: string, data2: string, data3: string, data4: string): string {
        return (
            data1?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data2?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data3?.replace("/" + new Date().getFullYear(), "") +
            ", " +
            data4?.replace("/" + new Date().getFullYear(), "")
        );
    }

    return (
        <div className="content-wrapper">
            <div className="content-heading">
                Administrar Módulos
                <div className="ml-auto">
                    <button className="btn btn-secondary btn-sm" type="button" onClick={handleCadastrar}>
                        Cadastrar módulo
                    </button>
                </div>
            </div>
            {(user === undefined || user.tipo !== "admin") && (
                <div className="row">
                    <div className="col-12">
                        <div className="denied alert alert-danger fade show" role="alert">
                            Acesso negado para {user?.tipo} {user?.email}
                        </div>
                    </div>
                </div>
            )}
            {user?.tipo === "admin" && (
                <div className="row">
                    {modulos &&
                        modulos.map((modulo, index) => {
                            return (
                                <ResumoModulo
                                    key={index}
                                    id={modulo.id}
                                    nome={modulo.nome}
                                    turma={modulo.turma}
                                    professor={modulo.professor}
                                    datas={datas(modulo.dataAula1, modulo.dataAula2, modulo.dataAula3, modulo.dataAula4)}
                                    ocupacao={Object.values(modulo.pesquisas ?? {}).filter((value) => !value.removidoPor).length}
                                    pesquisas={modulo.pesquisas}
                                    status={"Confirmado"}></ResumoModulo>
                            );
                        })}
                </div>
            )}
        </div>
    );
}
