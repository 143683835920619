import { useEffect, useState } from "react";
import swal from "sweetalert";

import { WithContext as ReactTags } from "react-tag-input";
import { database } from "../services/firebase";

type Tag = {
    id: string;
    text: string;
};

type Props = {
    avatar?: string;
    nome: string;
    cpf: string;
    email?: string;
    telefone?: string;
    termo?: boolean;
    questionario?: boolean;
    presente?: boolean;
    tags?: string[];
    presencial?: boolean;
    onRemover?: any;
    dataAula1?: string;
    dataAula2?: string;
    dataAula3?: string;
    dataAula4?: string;
};

export function Aluno(props: Props) {
    const [tags, setTags] = useState<Tag[]>([]);
    const [questD1, setQuestD1] = useState(false);
    const [questD2, setQuestD2] = useState(false);
    const [questD3, setQuestD3] = useState(false);
    const [questD4, setQuestD4] = useState(false);

    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];

    const suggestions = [
        { id: "USA", text: "USA" },
        { id: "Germany", text: "Germany" },
    ];

    const dateObj = new Date();

    async function editarTags() {
        // @ts-ignore
        const value = await swal("", {
            content: { element: document.getElementById("tagmanager") || "" },
        });
        console.log(value);
    }

    function handleDelete(i: number) {
        setTags(tags.filter((tag, index) => index !== i));
    }

    function handleAddition(tag: Tag) {
        setTags([...tags, tag]);
    }

    useEffect(() => {
        if (props.dataAula1) {
            const d1 = props.dataAula1.substr(6, 4) + "-" + props.dataAula1.substr(3, 2) + "-" + props.dataAula1.substr(0, 2);

            database
            .ref(`questionarios/${d1}`)
            .orderByChild("nome")
            .equalTo(props.nome)
            .once("child_added", function (snapshot) {
                console.log(snapshot.val());
                const data: { liberado: boolean } = snapshot.val();
                setQuestD1(true);
            });
        }

        if (props.dataAula2) {
            const d2 = props.dataAula2.substr(6, 4) + "-" + props.dataAula2.substr(3, 2) + "-" + props.dataAula2.substr(0, 2);

            database
            .ref(`questionarios/${d2}`)
            .orderByChild("nome")
            .equalTo(props.nome)
            .once("child_added", function (snapshot) {
                console.log(snapshot.val());
                const data: { liberado: boolean } = snapshot.val();
                setQuestD2(true);
            });
        }

        if (props.dataAula3) {
            const d3 = props.dataAula3.substr(6, 4) + "-" + props.dataAula3.substr(3, 2) + "-" + props.dataAula3.substr(0, 2);

            database
            .ref(`questionarios/${d3}`)
            .orderByChild("nome")
            .equalTo(props.nome)
            .once("child_added", function (snapshot) {
                console.log(snapshot.val());
                const data: { liberado: boolean } = snapshot.val();
                setQuestD3(true);
            });
        }

        if (props.dataAula4) {
            const d4 = props.dataAula4.substr(6, 4) + "-" + props.dataAula4.substr(3, 2) + "-" + props.dataAula4.substr(0, 2);

            database
            .ref(`questionarios/${d4}`)
            .orderByChild("nome")
            .equalTo(props.nome)
            .once("child_added", function (snapshot) {
                console.log(snapshot.val());
                const data: { liberado: boolean } = snapshot.val();
                setQuestD4(true);
            });
        }

        Inputmask().mask(document.querySelectorAll("#cpf"));
    }, []);

    return (
        <div className="media align-items-center">
            <img className="mr-2 rounded-circle img-thumbnail thumb48" src={props.avatar} alt="Contact" />
            <div className="media-body pt-1">
                <div className="float-right">
                    <button className="btn btn-secondary btn-sm" type="button" title="Tags" onClick={editarTags}>
                        <em className="fa fa-tags"></em>
                    </button>
                    <button className="btn btn-secondary btn-sm" type="button" title="Remover" onClick={props.onRemover}>
                        <em className="fa fa-minus"></em>
                    </button>
                </div>
                <div className="text-bold">
                    {props.nome}
                    {/*props.termo && <div className="badge badge-success ml-2">termo</div>*/}
                    {/*!props.termo && <div className="badge badge-warning ml-2">termo</div>*/}
                    {/*props.questionario && <div className="badge badge-success ml-2">questionario</div>*/}
                    {/*!props.questionario && <div className="badge badge-warning ml-2">questionario</div>*/}
                    {props.presencial && <div className="badge badge-info ml-2">presencial</div>}
                    {!props.presencial && <div className="badge badge-success light ml-2">online</div>}
                    {questD1 && (
                        <div className="badge badge-info ml-2">
                            {dateObj.toISOString().substr(8, 2)}/{dateObj.toISOString().substr(5, 2)}
                        </div>
                    )}
                    {questD2 && (
                        <div className="badge badge-info ml-2">
                            {dateObj.toISOString().substr(8, 2)}/{dateObj.toISOString().substr(5, 2)}
                        </div>
                    )}
                    {questD3 && (
                        <div className="badge badge-info ml-2">
                            {dateObj.toISOString().substr(8, 2)}/{dateObj.toISOString().substr(5, 2)}
                        </div>
                    )}
                    {questD4 && (
                        <div className="badge badge-info ml-2">
                            {dateObj.toISOString().substr(8, 2)}/{dateObj.toISOString().substr(5, 2)}
                        </div>
                    )}
                    {props.tags &&
                        props.tags.map((titulo) => {
                            return <div className="badge badge-info ml-2">{titulo}</div>;
                        })}
                    <div className="text-sm text-muted">
                        CPF {props.cpf} {props.telefone && `Telefone: ${props.telefone}`}
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <div id="tagmanager">
                    <div className="ReactTags">
                        <ReactTags tags={tags} suggestions={suggestions} handleDelete={handleDelete} handleAddition={handleAddition} delimiters={delimiters} />
                    </div>
                </div>
            </div>
        </div>
    );
}
