import { Link } from "react-router-dom";

export function NotFound() {
    return (
        <div className="abs-center wd-xl">
            <div className="text-center mb-4">
                <div className="text-lg mb-3">404</div>
                <p className="lead m-0">Página não encontrada.</p>
                <p>A página que você está procurando não existe.</p>
            </div>

            <ul className="list-inline text-center text-sm mb-4">
                <li className="list-inline-item">
                    <Link to="/" className="text-muted">
                        Voltar para o site
                    </Link>
                </li>
                <li className="text-muted list-inline-item">|</li>
                <li className="list-inline-item">
                    <Link to="login" className="text-muted">
                        Login
                    </Link>
                </li>
            </ul>
            <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>2021</span>
                <span className="mx-2">-</span>
                <span>Case Tecnologia</span>
            </div>
        </div>
    );
}
