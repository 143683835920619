import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Collapse, Badge } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { changeSetting } from "../../store/actions/actions";

import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";

import Menu, { ISidebarMenuItem } from "../../Menu";
import { useAuth } from "../../hooks/useAuth";

export interface SidebarItemHeaderProps {
    item: ISidebarMenuItem;
}
/** Component to display headings on sidebar */
export function SidebarItemHeader({ item }: SidebarItemHeaderProps) {
    return (
        <li className="nav-heading">
            <span>
                <Trans i18nKey={item.translate}>{item.heading}</Trans>
            </span>
        </li>
    );
}

export interface SidebarItemProps {
    item: ISidebarMenuItem;
    isActive: Boolean;
}
/** Normal items for the sidebar */
export function SidebarItem({ item, isActive }: SidebarItemProps) {
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={item.path!} title={item.name}>
                {item.label && (
                    <Badge tag="div" className="float-right" color={item.label.color}>
                        {item.label.value}
                    </Badge>
                )}
                {item.icon && <em className={item.icon}></em>}
                <span>
                    <Trans i18nKey={item.translate}>{item.name}</Trans>
                </span>
            </Link>
        </li>
    );
}

export interface SidebarSubItemProps {
    item: ISidebarMenuItem;
    isActive: boolean;
    handler: (e: React.MouseEvent) => void;
    children: React.ReactNode;
    isOpen: boolean;
}
/** Build a sub menu with items inside and attach collapse behavior */
export function SidebarSubItem({ item, isActive, handler, children, isOpen }: SidebarSubItemProps) {
    return (
        <li className={isActive ? "active" : ""}>
            <div className="nav-item" onClick={handler}>
                {item.label && (
                    <Badge tag="div" className="float-right" color={item.label.color}>
                        {item.label.value}
                    </Badge>
                )}
                {item.icon && <em className={item.icon}></em>}
                <span>
                    <Trans i18nKey={item.translate}>{item.name}</Trans>
                </span>
            </div>

            <Collapse isOpen={isOpen}>
                <ul id={item.path} className="sidebar-nav sidebar-subnav">
                    {children}
                </ul>
            </Collapse>
        </li>
    );
}

export interface SidebarSubHeaderProps {
    item: ISidebarMenuItem;
}
/** Component used to display a header on menu when using collapsed/hover mode */
export function SidebarSubHeader({ item }: SidebarSubHeaderProps) {
    return <li className="sidebar-subnav-header">{item.name}</li>;
}

interface StringBoolArray {
    [index: string]: boolean;
}

export interface SidebarProps extends RouteComponentProps, WithTranslation {
    changeSetting: typeof changeSetting;
}

function Sidebar({ history, location, changeSetting }: SidebarProps) {
    const { user } = useAuth();
    const [collapse, setCollapse] = useState<StringBoolArray>({});

    useEffect(() => {
        // pass navigator to access router api
        SidebarRun(navigator, closeSidebar);
        // prepare the flags to handle menu collapsed states
        buildCollapseList();

        // Listen for routes changes in order to hide the sidebar on mobile
        history.listen(closeSidebar);
    }, [location]);

    // componentDidUpdate(prevProps: any) {
    //   if (this.location !== prevlocation) {
    //     this.buildCollapseList();
    //   }
    // }

    function closeSidebar() {
        changeSetting({ name: "asideToggled", value: false });
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    function buildCollapseList() {
        let collapse = {} as StringBoolArray;
        Menu.filter(({ heading }) => !heading).forEach(({ name, path, submenu }) => {
            if (name) collapse[name] = routeActive(submenu ? submenu.map(({ path }) => path) : path);
        });
        setCollapse(collapse);
    }

    function navigator(route: string) {
        history.push(route.replace("#", "")); // remove '#' in case of use HashRouter
    }

    function routeActive(paths: string | Array<string | undefined> | undefined) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some((p) => {
            return p && location.pathname.indexOf(p) > -1;
        });
    }

    const toggleItemCollapse = (stateName: string) => {
        // const { collapse } = this.state;
        // for (let c in collapse) {
        //     if (collapse[c] === true && c !== stateName)
        //         this.setState({
        //             collapse: {
        //                 [c]: false,
        //             },
        //         });
        // }
        // this.setState({
        //     collapse: {
        //         [stateName]: !collapse[stateName],
        //     },
        // });
        //console.log(collapse)
        setCollapse(Object.entries(collapse).reduce((acc, [itemName, item]) => (stateName === itemName ? { ...acc, [itemName]: !item } : acc), collapse));
        //let tn = Object.entries(collapse).reduce((acc, [itemName, item]) => (stateName === itemName ? { ...acc, [itemName]: !item } : acc), collapse);
        //console.log(tn);
        //console.log("collapse:" + stateName);

        //let newCollapse = collapse;
        //newCollapse["Início"] = true;
        // for (let c in newCollapse) {
        //     console.log(c)
        //     if (newCollapse[c] === true && c !== stateName) {
        //         newCollapse[c] = false;
        //     }
        // }
        // newCollapse[stateName] = !newCollapse[stateName];
        // console.log(Object.entries(tn))
        // console.log(Object.entries(newCollapse))
        // console.log(newCollapse);
        // setCollapse(newCollapse);
    };

    function getSubRoutes(item: ISidebarMenuItem): Array<string | undefined> {
        return item.submenu ? item.submenu.map(({ path }) => path) : [];
    }

    /** map menu config to string to determine which element to render */
    function itemType(item: ISidebarMenuItem) {
        if (item.heading) return "heading";
        if (!item.submenu) return "menu";
        if (item.submenu) return "submenu";
    }

    return (
        <aside className="aside-container">
            {/* START Sidebar (left) */}
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    {/* START sidebar nav */}
                    <ul className="sidebar-nav">
                        {/* START user info */}
                        <li className="has-user-block">
                            <SidebarUserBlock />
                        </li>
                        {/* END user info */}

                        {/* Iterates over all sidebar items */}
                        {Menu.map((item, i) => {
                            if (item.restrict && user?.tipo !== "admin") {
                                //return null;
                            }
                            // heading
                            if (itemType(item) === "heading") return <SidebarItemHeader item={item} key={i} />;
                            else {
                                if (itemType(item) === "menu") return <SidebarItem isActive={routeActive(item.path)} item={item} key={i} />;
                                if (itemType(item) === "submenu")
                                    return (
                                        item.name && [
                                            <SidebarSubItem
                                                item={item}
                                                isOpen={collapse[item.name]}
                                                handler={() => item.name && toggleItemCollapse(item.name)}
                                                isActive={routeActive(getSubRoutes(item))}
                                                key={i}>
                                                <SidebarSubHeader item={item} key={i} />
                                                {item.submenu &&
                                                    item.submenu.map((subitem, i) => (
                                                        <SidebarItem key={i} item={subitem} isActive={routeActive(subitem.path)} />
                                                    ))}
                                            </SidebarSubItem>,
                                        ]
                                    );
                            }
                            return null; // unrecognized item
                        })}
                    </ul>
                    {/* END sidebar nav */}
                </nav>
            </div>
            {/* END Sidebar (left) */}
        </aside>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ changeSetting: bindActionCreators(changeSetting, dispatch) });

export default connect(null, mapDispatchToProps)(withTranslation("translations")(withRouter(Sidebar)));
