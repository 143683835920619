import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Professor } from "../params";
import { database } from "../services/firebase";

export function useProfessores(): Professor[] {
    const { user } = useAuth();
    const [professores, setProfessores] = useState<Professor[]>([]);

    useEffect(() => {
        if (user === undefined || user.id === undefined || user.tipo !== "admin") {
            return;
        }

        const ref = database.ref("professores");
        ref.orderByChild("nome").on("value", (snapshot) => {
            console.log("snapshot professores");
            let parsedValues: Professor[] = [];

            snapshot.forEach((instance) => {
                let professor: Professor = instance.val();
                professor.id = instance.key || "";

                parsedValues.push(professor);
            });
            setProfessores(parsedValues);
        });

        return () => {
            ref.off();
        };
    }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return professores;
}
